import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Faculty extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Taxation Courses" />
        <div className="banner-body">
          <div className="banner-content">
            <h3 className="caption">OUR ESTEEMED FACULTY</h3>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/hgXVxMW/CA-Dr-Arpit-Haldia.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. (Dr.) Arpit Haldia</h4>
                <h5 className="ml-0 bold">
                  - FCA, CS, CWA, Ph.d, LLB, DISA (ICAI), DIRM (ICAI), B.Com
                </h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    More than 18 years of experience in the field of Direct
                    taxes, Indirect taxes & Audit and currently is a Partner in
                    M/S Paritosh Gupta & Co. Chartered Accountants, Jodhpur –
                    Rajasthan.
                  </li>
                  <li>
                    {" "}
                    He has deliberated 700+ Seminars and Webinars across INDIA
                    organized by various committees & branches of Institute of
                    Chartered Accountants of India (ICAI) and other trade and
                    professional bodies.
                  </li>
                </ul>

                <h5 className="ml-0 bold">Significant Achievements:</h5>

                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Author of various books on GST: “GST Made Easy”, “GST Mini
                    Ready Reckoner”, “GST Samasya Evam Samadhan” and “GST E-Way
                    Bill” which have been published by Taxmann Publications.
                  </li>
                  <li>
                    {" "}
                    He has also written various articles and blogs on GST,
                    published in various journals, newspapers and websites
                    including Taxmann, gst-online.com, etc.
                  </li>
                  <li>
                    {" "}
                    Ph.D. in “Comparative Study of Existing system of Sales Tax
                    and Proposed system of Value Added Tax”
                  </li>
                  <li>
                    {" "}
                    Visiting Faculty for NACIN for Training of CGST Officials
                    and Deloitte Touche Tohmatsu India LLP for Training of SGST
                    officials in Rajasthan{" "}
                  </li>
                  <li>
                    {" "}
                    Secured All India Rank (AIR) 36th in CA Intermediate
                    Examination
                  </li>
                  <li> Written 600+ blogs on GST</li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Member District Level GST Committee Jodhpur, in pursuance of
                    Order of State Government of Rajasthan Secretary
                  </li>
                  <li> Tax Bar Association, Jodhpur 2009-2011</li>
                  <li>
                    {" "}
                    Secretary, Jodhpur Branch of CIRC of ICAI for the term
                    2012-13
                  </li>
                  <li>
                    {" "}
                    Treasurer, Jodhpur Branch of CIRC of ICAI for the term
                    2011-12
                  </li>
                  <li>
                    {" "}
                    CICASA Chairman, Jodhpur Branch of CIRC of ICAI for the term
                    2010-11
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/JBgcsbg/CA-Aanchal-Kapoor.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. Aanchal Kapoor</h4>

                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    A Qualified Chartered Accountant (CA) and is practicing for
                    past 10+ years in the field of direct and indirect taxes
                    including Appellate work.
                  </li>
                  <li>
                    {" "}
                    She has authored many articles on GST, published in various
                    journals like AIFTP, Tax Management India, etc.
                  </li>
                  <li>
                    {" "}
                    With special focus on GST she has done extensive study on
                    the subject with many certified courses and GST is one of
                    her core competency areas.
                  </li>
                  <li>
                    {" "}
                    Since inception of her academics, she has been placed in the
                    merit list at various levels as a Rank holder in CA and Gold
                    medalist in graduation.
                  </li>
                  <li>
                    {" "}
                    In pursuing her professional career in GST, she is
                    practically into this field and has delivered various
                    seminars
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/LP4Zvz4/CA-Vijay-Agarwal.jpg"
                />
              </div>
              <div>
                <h4 className="ml-0 mt-0 bold">CA. Vijay Agarwal</h4>
                <h5 className="ml-0 bold">
                  Chartered Accountant (CA) and Company Secretariat (CS)
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Awarded as "Most Valuable Professional" (MVP) by Microsoft
                    for consecutive 4 years (2014 to 2018). There are less than
                    100 MVPs in the whole world.
                  </li>
                  <li>
                    {" "}
                    Deliberated 400+ Workshops / Seminars / Webinars across
                    INDIA organized by various committees & branches of
                    Institute of Chartered Accountants of India (ICAI) and other
                    corporate and professional bodies.
                  </li>
                  <li>
                    {" "}
                    Faculty for ICAI Forensic Audit and Fraud Detection (FAFD)
                    Program
                  </li>
                  <li> Invited by Microsoft at Redmond, USA for MVP award</li>
                </ul>

                <h5 className="ml-0 bold">Professional Experience:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    More than 27 years of experience in the field of Microsoft
                    Excel and PowerPoint
                  </li>
                  <li>
                    {" "}
                    Currently Mr. Vijay is providing Microsoft Excel Solutions
                    and Trainings to Corporates /Institutes, automating
                    processes by using Excel and BI Tools. Previously he has
                    worked with Telenor, Capgemini, Aircel, Hughes, Jindal’s and
                    Havells.
                  </li>
                  <li>
                    {" "}
                    Corporate Clients: PWC, HMEL, GAIL, DLF, EY, Sapient,
                    Tupperware, EXL Services, VIOM, Sterlite, Diageo, Hughes,
                    Telenor, Tata Communications, Times Group, Bacardi,
                    Siegwerk, Peak DMC
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/6vh3hsv/CA-Nitin-Kanwar.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. Nitin Kanwar </h4>
                <h5 className="ml-0 bold">
                  FCA, LLB, B. Com(Hons.), Certified - International Taxation
                </h5>
                <h5 className="ml-0 bold">-Member NIRC of ICAI (2016-2019)</h5>
                <h5 className="ml-0 bold">
                  -Re-elected Member NIRC of ICAI (2019-2022)
                </h5>

                <h4 className="ml-0 mt-0 bold">
                  Nitin Kanwar is the Managing Partner of A J K R & Associates,
                  one of the leading chartered accountancy firms in India with
                  the offices in Gurgaon, New Delhi, and Daryaganj.
                </h4>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Deliberated various sessions on International taxation,
                    PMLA, Benami Act, PMGKY Scheme, Transfer Pricing, Income
                    Computation and Disclosure Standards (ICDS).{" "}
                  </li>
                  <li>
                    {" "}
                    Shared his knowledge in different cities like Shimla,
                    Amritsar, Bhatinda, Panipat, Sonipat, Karnal, Rohtak,
                    Faridabad, New Delhi & other various prominent Study Circles
                    of ICAI
                  </li>
                  <li>
                    {" "}
                    Actively engaged in writing various articles, literatures
                    etc. & also has won “BEST CONTRIBUTOR OF ARTICLE” in News
                    Letter of NIRC (Northern India Regional Council) of ICAI
                    (Institute of Chartered Accountants of India) in Year 2007
                  </li>
                  <li>
                    {" "}
                    Rewarded with Best Deputy Convener Award in Year 2009
                  </li>
                  <li>
                    {" "}
                    Provides Professional updates on daily basis to a community
                    named as "CA NEWS TODAY" having 58,000+ Chartered
                    Accountants.
                  </li>
                </ul>

                <h5 className="ml-0 bold">Expertise:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    His field of specialization is Direct Taxes and especially
                    International Taxation.
                  </li>
                  <li>
                    {" "}
                    He has handled matters of clients from diverse industries
                    and has represented them before various authorities. 
                  </li>
                  <li>
                    {" "}
                    His depth knowledge in International Tax matters, Foreign
                    Exchange regulations and Transfer pricing has allowed him to
                    successfully advise multinational companies on a host of
                    issues liked FDI Policy, entry strategy, setting up offices
                    in India, cross border tax structuring, transfer pricing,
                    mergers & acquisitions, inbound investment structuring, tax
                    controversy matters and other regulatory matters.{" "}
                  </li>
                  <li>
                    {" "}
                    He is an independent and specialist provider of expert
                    transfer pricing, tax valuation, consultancy services,
                    corporate matters etc. to his foreign clients headquartered
                    in Denmark, Thailand, German, Japan, Belgium US, etc.
                  </li>
                </ul>

                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Deputy Convener of East End CPE study Circle, from 2003 to
                    2015, having Membership of more than 850 Chartered
                    Accountants
                  </li>
                  <li> Member NIRC of ICAI (2016-2019)</li>
                  <li> Re-elected Member NIRC of ICAI (2019-2022)</li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/KL52wTL/CA-Navya-Malhotra.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. Navya Malhotra</h4>
                <h5 className="ml-0 bold">
                  B. Com(Hons.), M. Com, ACA, DISA, Certified GST
                </h5>
                <h5 className="ml-0 bold">Member - ICAI</h5>
                <h5 className="ml-0 bold">
                  Navya Malhotra is a Member of Institute of Chartered
                  Accountants of India (ICAI)
                </h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    He has completed his Diploma in Information Systems Audit
                    (D.I.S.A) from the Institute of Chartered Accountants of
                    India{" "}
                  </li>
                  <li>
                    {" "}
                    He has also successfully cleared the GST Certification held
                    by the Institute of Chartered Accountants of India as post
                    qualification certification and has done graduation from
                    Delhi University (DU),{" "}
                  </li>
                </ul>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Addressed and deliberated 250+ Seminars / Webinars across
                    the country in / to various Tax Associations, ICAI, Senior
                    IAS officials, Military Officials at the Indian Institute of
                    Public Administration Training and Research, Senior banking
                    officials at the National Institute of Banking Studies and
                    Corporate Management (NIBSCOM)-Delhi and others.
                  </li>
                </ul>

                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Special Invitee to the Indirect Tax Committee of the ICAI
                    (2020-21)
                  </li>
                  <li>
                    {" "}
                    Registered and empaneled faculty with the Indirect Tax
                    Committee of the ICAI
                  </li>
                  <li> Faculty with the NIRC of the ICAI</li>
                  <li>
                    {" "}
                    Chief editor and mentor to the venture “TaxVisiors India”
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/58VBTSr/Dhanasekar.png"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">Mr. Dhanasekar (Tally Sekar)</h4>

                <h5 className="ml-0 bold">
                  Mr. Dhanasekar is practicing as a Tax Consultant since 1996
                  and provides Professional Accounting Service for individuals
                  businesses and government clients, from tax preparation to
                  audit support, financial statement preparation, Pro forma
                  budgeting, GL accounting and Bank Reconciliation, Filing for
                  Income Tax & GST Returns.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Addressed more than 100 seminars and webinars in Tamil Nadu
                    and Puducherry including in Salem, Dharmapuri, Krishnagiri,
                    Hosur, Coimbatore, Chennai, Trichy, Madurai, Namakkal,
                    Karur, T.V.Malai, Kallakurichi, Mettur and Attur.
                  </li>
                  <li>
                    {" "}
                    Award Winner for Master Trainer of Accounting Software and
                    Return Filing related Software.
                  </li>
                  <li>
                    {" "}
                    Lecturer in KRC (Knowledge Resource Centre, Erode, Tamil
                    Nadu) for more than 10 years.
                  </li>
                  <li>
                    {" "}
                    Given many training sessions for Accounting Softwares to
                    ample students and professionals across India
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/Ld97jrx/CA-Rathinagiri-S.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
                  CA. Rathinagiri S. - F.C.A, DISA - ICAI
                </h4>

                <h5 className="ml-0 bold">
                  CA. Rathinagiri S. is a Fellow Member of the Institute of
                  Chartered Accountants of India and is a practicing with M/s.
                  Shenbagamoorthy & Co., Sivakasi, Tamil Nadu and is passionate
                  about software and application development.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Developed open Source Interest Calculator being used by the
                    Federal Bank
                  </li>
                  <li>
                    {" "}
                    Assisted in developing HMG, a free Windows API for Harbour
                    XBase language.
                  </li>
                  <li> Involved in implementing REP & DSS.</li>
                  <li> Developed many Business application software's</li>
                  <li> Teaching and guiding CA students since the year 2002</li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Faculty Member for Information Systems Audit Course in ICAI
                  </li>
                  <li>
                    {" "}
                    Co-Ordinator and Faculty member for ITT/ICITSS of the
                    Sivakasi Branch of ICAI since its inception.
                  </li>
                  <li> Treasurer, SHNUM Fund</li>
                  <li> Secretary of Sivakasi Exnora Innovators' Club</li>
                  <li>
                    {" "}
                    Secretary of Sivakasi Chartered Accountants Association
                  </li>
                  <li> Secretary of Lions Club of Sivakasi Industrial Town</li>
                  <li> Secretary of Sivakasi Region Tax Payers Association</li>
                  <li>
                    {" "}
                    Secretary, Treasurer of TamilNadu Fireworks and Amorces
                    Manufacturers Association (TANFAMA)
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/mSzfZmY/CS-A-R-Sethu.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
                  CS. A.R. Sethu (A.C.S, MBA - Finance, B.Com)
                </h4>

                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    A.R. Sethu is a Member of Institute of Company Secretaries
                    of India, along with Master of Business Administration
                    degree in finance and a Bachelor's degree.
                  </li>
                  <li>
                    {" "}
                    He has more than 18 years of experience in Indirect
                    Taxation, Company Law, specializing in all aspects of
                    Service Tax, Value Added Tax (VAT) / Central Sales Tax
                    (CST), and well acquainted with the concept and impact of
                    Goods and Services tax (GST).
                  </li>
                  <li>
                    {" "}
                    He has also Appeared before quasi-judicial authorities.
                  </li>
                  <li>
                    {" "}
                    He has a blend of Industry and Consulting experience in
                    renowned entities viz. Standard chartered bank, Paharpur
                    Industries, and currently he is practicing as a Tax
                    consultant and Company Secretary in Coimbatore.
                  </li>
                </ul>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Mr. Sethu has delivered Guest lectures for many Tax
                    Practitioners Associations, Professional institutes in
                    Chennai, Coimbatore, Salem, Erode, Karur, Tanjore, Tirupur,
                    Yercard, Tirunelveli, Pondicherry, Kerala etc. on GST and
                    Company Law.
                  </li>
                  <li>
                    {" "}
                    He is a visiting faculty for various colleges and
                    professional institutes
                  </li>
                  <li>
                    {" "}
                    He is also an author of GST book in Tamil and currently he
                    is writing another book on GST named as “Practical
                    difficulties in GST - Questions and Answers” in Tamil and
                    English
                  </li>
                  <li>
                    {" "}
                    He has addressed 350+ sessions on GST, all over INDIA
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/7ncwTN3/CA-Dr-Gopal-Krishna-Raju.jpg"
                />
              </div>

              <div>
                <h4 className="ml-0 mt-0 bold">CA. Dr. Gopal Krishna Raju</h4>

                <h5 className="ml-0 bold">
                  CA. Dr. Gopal Krishna Raju (GKR) [FCA, ACWA, ACMA, ACS, PhD.,
                  PGDOR, PGDFM, DISA, M.Phil.] is a Practicing Chartered
                  Accountant, Registered Insolvency Professional and a
                  Registered Valuer (S/FA) from Chennai.
                </h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    He is the Tax, Assurance & Restructuring Partner of M/s K
                    GOPAL RAO & CO., Chartered Accountants (a public accounting
                    firm with branches at Mumbai, Bangalore, Hyderabad, Madurai,
                    Tiruchirappalli & Thiruvallur).
                  </li>
                  <li>
                    {" "}
                    As a versatile speaker, GKR has addressed more than 400+
                    Seminars to various forums including, Professional Bodies,
                    Chamber of Commerce, Industry & Trade Associations in both
                    English & Tamil.
                  </li>
                </ul>
                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    He has also been awarded Honorary Doctorate (Honoris Causa)
                    in “Governance of GST in Government Sector” by St. Mother
                    Teresa University, Melbourne, Australia.
                  </li>
                  <li>
                    {" "}
                    GKR has authored various books including “Standard Costing &
                    Variance Analysis” for Professional Students, “First Lessons
                    in Information Technology” for CA students. It has been
                    published by Snow White Publishers, Mumbai
                  </li>
                  <li>
                    {" "}
                    He is also in the Independent Directors Databank and have
                    qualified with distinction the Online Proficiency
                    Self-Assessment Test conducted by Indian Institute of
                    Corporate Affairs (under the aegis of Ministry of Corporate
                    Affairs, Government of India)
                  </li>
                  <li>
                    {" "}
                    GKR is a passionate writer on technology & taxation in
                    numerous journals and newspapers; columnist in Tamil Daily
                    “THE HINDU TAMIL” and weekly Tamil magazines “NANAYAM
                    VIKATAN” & “PUTHIYA THALAIMURAI KALVI”; Regularly
                    appears/interviewed in numerous Tamil TV Channels and Tamil
                    FM Radios including “DD POTHIGAI” “JAYA TV”, News 18 Tamil
                    Nadu”, “Cauvery News”; “FM Rainbow” 
                  </li>
                </ul>
                <h5 className="ml-0 bold">Other Qualifications:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Qualified Valuation (S/FA) Examination of Insolvency &
                    Bankruptcy Board of India
                  </li>
                  <li>
                    {" "}
                    Qualified Limited Insolvency Examination of Insolvency &
                    Bankruptcy Board of India 
                  </li>
                  <li>
                    {" "}
                    Associate member of The Institute of Cost Accountants of
                    India (formerly ICWAI) with all India rank in intermediate
                    examination. 
                  </li>
                  <li>
                    {" "}
                    Associate member of The Institute of Company Secretaries of
                    India (ICSI). 
                  </li>
                  <li>
                    {" "}
                    Twin Post-Graduate Diploma holder in Operation Research and
                    in Financial Management from Pondicherry (Central)
                    University
                  </li>
                  <li> M.PHIL in Management from Tamil Nadu open university</li>
                  <li>
                    {" "}
                    Qualified Information Systems Auditor (ISA) from The
                    Institute of Chartered Accountants of India (ICAI) and
                    Qualified Part I of Post Qualification Course in
                    International Trade Laws and World Trade Organization (DITL)
                    from ICAI
                  </li>
                  <li>
                    {" "}
                    Holds eight NSE’s certification in Financial Markets (NCFM)
                    from National Stock Exchange of India Limited (NSEIL) viz.
                    Capital Market (Dealers), Derivatives Markets (Dealers),
                    Securities Market (Basic), FIMMDA-NSE Debt Market (Basic)
                    Modules, Commodity Market, AMFI-Mutual Funds (Basic),
                    AMFI-Mutual Funds (Advisors) and Corporate Governance
                    Modules
                  </li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Finance and Taxation Panel Member of FICCI, Tamil Nadu State
                    Council
                  </li>
                  <li>
                    {" "}
                    Chairman, Valuation Standards Board, All India Institute of
                    Valuers Foundation
                  </li>
                  <li>
                    {" "}
                    Served consecutive three terms of 9 years from 2010-2019 as
                    Southern India Regional Council Member of The Institute of
                    Chartered Accountants of India (SIRC of ICAI)
                  </li>
                  <li>
                    {" "}
                    Member of Institute of Directors, Bombay Chartered
                    Accountants Society and Madras Management Association AND
                    Life Member of the Society of Auditors, The Chartered
                    Accountant Study Circle and an Active Rotarian
                  </li>
                  <li>
                    {" "}
                    Valuation Standards Board, Institution of Valuers –
                    Registered Valuation Foundation 
                  </li>
                  <li>
                    {" "}
                    Academic Board, Department of Commerce, Loyola College
                    (Autonomous), Chennai
                  </li>
                  <li>
                    {" "}
                    Academic Council Member, DG Vaishnava College (Autonomous),
                    Chennai
                  </li>
                  <li>
                    {" "}
                    Academic Advisory Board, SDNB Vaishnava College for Women
                    (Autonomous), Chennai
                  </li>
                  <li> Academic Board, SIVET College, Chennai</li>
                  <li> Board of Studies, New College (Autonomous), Chennai</li>
                  <li>
                    {" "}
                    Board of Studies, Guru Nanak College (Autonomous), Chennai
                  </li>
                  <li>
                    {" "}
                    Audit Advisory Board, Director General of Audit (Central),
                    Chennai
                  </li>
                  <li>
                    {" "}
                    Executive Committee, Centre for Entrepreneurial Development,
                    Anna University, Chennai
                  </li>
                  <li>
                    {" "}
                    Expert Committee on Corporate, Allied Laws, CSR, Governance
                    & Ethics of Hindustan Chamber of Commerce.
                  </li>
                  <li> Steering Committee, Institute of Directors, Chennai</li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/RzFqVVP/CA-Archana-Jain.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. ARCHANA JAIN</h4>
                <h5 className="ml-0 bold">
                  - B. Com (Hons.) from Shri Ram College of Commerce - Delhi
                  (SRCC) and Chartered Accountant
                </h5>

                <h5 className="ml-0 bold">
                  More than 10 years of Experience in the field of Indirect
                  taxation, represented many reputed corporate houses at service
                  tax/Customs department for appeals up to CESTAT and currently
                  working as a founder at Archana Jain & Co.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Addressed More than 100 workshops / seminars / webinars
                    organized by FICCI, PHD Chamber, ICAI and various other
                    associations{" "}
                  </li>
                  <li>
                    {" "}
                    Deliberated Certification courses organized by Institute of
                    Chartered Accountants of India (ICAI)
                  </li>
                  <li>
                    {" "}
                    Trainer at Post And Telegraph Academy for GST Training.
                  </li>
                  <li>
                    {" "}
                    Speaker at GST Office - Delhi for deliberating on GST
                    Refunds and Issues under GST
                  </li>
                </ul>

                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    GMCS Faculty at Institute of Chartered Accountant of India
                  </li>
                  <li>
                    {" "}
                    GST Faculty at Institute of Chartered Accountant of India
                    (FIP)
                  </li>
                  <li>
                    {" "}
                    GST Faculty at Federation of Indian Chambers of Commerce &
                    Industry (FICCI)
                  </li>
                  <li>
                    {" "}
                    UAE - VAT faculty at Institute of Chartered Accountant of
                    India (FIP)
                  </li>
                  <li> GST Visiting Speaker at PHD Chambers</li>
                  <li>
                    {" "}
                    Guest Speaker at Birla Institute of Technology - Noida
                  </li>
                  <li> Guest Speaker at NIFT – Delhi</li>
                  <li>
                    {" "}
                    Visiting faculty of Northern India Regional Council of ICAI
                  </li>
                  <li> Visiting faculty of NICASA under the aegis of NIRC</li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/vD3RJkG/CA-Deepak-Bhholusaria.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. DEEPAK BHHOLUSARIA</h4>

                <h5 className="ml-0 bold">
                  More than 21 years of Experience as a Professional, Author and
                  Speaker.
                </h5>
                <h5 className="ml-0 bold">
                  Currently working as Principal Partner in Intellia Advisors,
                  LLP.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Addressed More than 200 workshops / seminars / webinars
                    organized by ICAI and various other associations{" "}
                  </li>
                  <li>
                    {" "}
                    Deliberated Certification courses organized by Institute of
                    Chartered Accountants of India (ICAI)
                  </li>
                  <li>
                    {" "}
                    Completed various certificate courses of Institute of
                    Chartered Accountants of India (ICAI) on Information Systems
                    Audit, Valuation and Indirect taxes
                  </li>
                  <li>
                    {" "}
                    Awarded by NIRC of Institute of Chartered Accountants of
                    India (ICAI) for his contribution as speaker in seminars for
                    various years
                  </li>
                </ul>

                <h5 className="ml-0 bold">Professional Experience:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    His clientele ranges from SME to Fortune 100 companies. 
                  </li>
                  <li>
                    {" "}
                    Experienced in handling various kinds of assignments
                    including GST consulting, impact study and implementation,
                    Management advisory - Tax compliance and litigations
                  </li>
                  <li>
                    {" "}
                    His Industry experience includes: E-Commerce Industry,
                    Automobile Components Industry, Education Industry,
                    Telecommunications Industry, Packaging Industry, Information
                    Technology, Dental care Industry, Civil Construction
                    Industry, Manufacturing and service industry
                  </li>
                </ul>

                <h5 className="ml-0 bold">
                  Deliberated at various National Level Platforms :
                </h5>
                <ul className="faculty-list-style">
                  <li> National Television – For talk shows</li>
                  <li> BVSS – Bhartiya Vitya Salahakar Samiti</li>
                  <li>
                    {" "}
                    FTAP – Federation of Tax and Accounting Professionals
                  </li>
                  <li>
                    {" "}
                    Various associations of Chartered Accountants located in New
                    Delhi at Patparganj,
                  </li>
                  <li> Laxmi Nagar, Joshi Road, and Mayur Vihar - New Delhi</li>
                  <li> Chartered World (a monthly magazine)</li>
                  <li> Jammu Branch of NIRC of ICAI – Workshop on GST</li>
                  <li>
                    {" "}
                    Bathinda Branch of NIRC of ICAI – Refresher course on GST
                  </li>
                  <li>
                    {" "}
                    Noida Branch of NIRC of ICAI – Certificate course on GST
                  </li>
                  <li>
                    {" "}
                    Inhouse clients and clients of other fellow professionals.
                  </li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Secretary - Information Technology at National Chartered
                    Accountants Society (2009-13)
                  </li>
                  <li>
                    {" "}
                    Member - Banking Research Group - NIRC of Institute of
                    Chartered Accountants of India (ICAI) (2010-13)
                  </li>
                  <li>
                    {" "}
                    Deputy Convener - Patparganj CPE Study Circle - NIRC of
                    Institute of Chartered Accountants of India (ICAI) (2007-08)
                  </li>
                  <li>
                    {" "}
                    Joint Convener - Information Technology Study Group - NIRC
                    of Institute of Chartered Accountants of India (ICAI) -
                    (2007-08)
                  </li>
                  <li>
                    {" "}
                    Co-opted Member - Information Technology Study Group - NIRC
                    of Institute of Chartered Accountants of India (ICAI)
                    (2008-09)
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/MDjpntZ/CA-M-Saravana-Prabhu.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
                  CA. M. Saravana Prabhu (FCA, ACMA)
                </h4>

                <h5 className="ml-0 bold">
                  Sh. Saravana Prabhu is a Chartered Accountant in Practice for
                  over a decade in the field of taxation. He handles compliance,
                  litigation & consultancy in GST, Income Tax, Service Tax,
                  Excise, Customs & VAT.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Mr. Saravana Prabhu has addressed almost 100+
                    Technical/Public outreach Programs in GST covering more than
                    20,000+ people in more than 7 Districts in Tamil Nadu in
                    association with National Academy of Central Excise and
                    Narcotics (Govt of India), ICAI, Dinamalar Newspaper etc.
                  </li>
                  <li>
                    {" "}
                    He secured an All India Rank Holder in CA Examinations and
                    AIR 7th Rank in ICMA Exams.
                  </li>
                  <li>
                    {" "}
                    He has authored various books on GST such as “A Beginner's
                    Guide to GST & Indirect Tax” and is also a Part of Editorial
                    Board of various Books on GST.
                  </li>
                  <li>
                    {" "}
                    He has also appeared in numerous TV Programs, Interviews
                    relating to GST / Finance in prominent TV Channels like
                    Pudhiya Thalaimurai, Thanthi TV etc.
                  </li>
                  <li>
                    {" "}
                    He is also engaged in training Organizations / Central
                    Excise and Service Tax Department (CBEC - NACEN) in various
                    avenues of Indirect Taxation including Budget Analysis
                    Lectures for Trade Associations and Budget Impact Analysis.
                  </li>
                </ul>
              </div>
            </section>
            <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/C8ZWJTz/Manoj-Lamba.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
                  CA. Manoj Lamba (FCA, LL.B., CS. (Final Level Pass), B. Com)
                </h4>

                <h5 className="ml-0 bold">
                  Sh. Manoj Lamba is a Fellow Member of the Institute of
                  Chartered Accountants of India (ICAI) and has vast experience
                  of 21 years in Auditing, Income Tax & Company Law. Currently,
                  he is practicing with Anurag Manoj & Associates.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Deliberated more than 100 Workshops/Seminars/Webinars
                    organized by the Institute of Chartered Accountants of India
                    (ICAI), Income Tax Department, Yamuna Nagar-Jagadhri Income
                    Tax Council Association, and various other associations,
                    educational institutes, and colleges.
                  </li>
                  <li>
                    {" "}
                    Conducted Orientation & GMCS programs for CA Students.
                  </li>
                  <li>
                    {" "}
                    Written various Articles and Blogs in Journals, Websites,
                    and Social Media.
                  </li>
                  <li>
                    {" "}
                    Author of a very well-appreciated book named - A Simplified
                    Guide to GST.
                  </li>
                  <li>
                    {" "}
                    Also has his own YouTube Channel named: Tax Ki Pathshala,
                    where he educates Chartered Accountants, Other
                    Professionals, and Students as well.
                  </li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Chairman - Yamuna Nagar branch of NIRC of the Institute of
                    Chartered Accountants of India (ICAI) - 2017-18
                  </li>
                  <li>
                    {" "}
                    Vice-Chairman - Yamuna Nagar branch of NIRC of the Institute
                    of Chartered Accountants of India (ICAI) - 2013-14
                  </li>
                  <li>
                    {" "}
                    Treasurer - Yamuna Nagar branch of NIRC of the Institute of
                    Chartered Accountants of India (ICAI) - 2014-15
                  </li>
                  <li>
                    {" "}
                    Secretary - Yamuna Nagar Jagadhri Income Tax Council
                    Association (2012-13 & 2015-16)
                  </li>
                </ul>
              </div>
            </section>
    <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/KsfX7GD/CA-Parveen-Sharma.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
                  CA. Praveen Sharma (Diploma in IFRS from ACCA UK, LL.B, M. Com (Finance & Taxation), B. Com (Hons.)) 

                </h4>

                <h5 className="ml-0 bold">
                 CA. Praveen Sharma is a Chartered Accountant in Practice for over a decade in the field of taxation. 
                 Currently practicing as a Partner in M/s. Sarupria Somani & Associates, a Chartered Accountancy Firm.

                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Awarded by K.V. Chandramouli Memorial Prize from The Institute of Chartered Accountants of India (ICAI).
                  </li>
                  <li>
                    {" "}
                    He is a “Trainer (On behalf of GSTN)” of the GST Project on “GST Portal Master Trainer’s Training” by Infosys, Chennai in Feb-2017 and had trained more than 15,000 participants across the country.
                  </li>
                  <li>
                    {" "}
                    He has been associated with Study Circles of ICAI, Tax Bar Association & Trade Associations in the country as a Faculty of Indirect Taxation and on latest developments.
                  </li>
                  
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Member of Eminent Faculties in “Indirect Tax Committee” of ICAI.
                  </li>
                  
                  
                </ul>
              </div>
            </section>
    <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/WHxH4yL/Nihalchand-Jain-photo.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
CA. Nihalchand Jain
                </h4>

                <h5 className="ml-0 bold">
                 CA. Nihalchand Jain is a member of the Institute of Chartered Accountants of India (ICAI) having more than four years of professional experience working with a broad range of global, public, and private organizations, helping them deal with their significant GST risks and issues.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
He is an alumnus of the prestigious H. R. College (Mumbai)                  </li>
                  <li>
                    {" "}
                    His professional experience includes business & operational risk advisory, GST audit, GST Compliance, Setting up of Standard Operating Procedures (SOPs), etc.
                  </li>
                  <li>
                    {" "}
                   Considering his special inclination towards GST he has been invited as a regular speaker at various forums like GST Panacea and is associated with institutes like ICAI, UPACA to speak to CA students both academically as well as on practical aspects alongside contributing to various magazines, and  journals.
                  </li>
                  
                </ul>
                
              </div>
            </section>
        <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/hXxvZnx/Aditya-Singhania.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
CA. Aditya Singhania
                </h4>

                <h5 className="ml-0 bold">
CA. Aditya Singhania has authored a few books on GST since the advent of GST like GST Registration, GST on Transition, GST Refunds, GST Audit & Annual Return, GST Practice Manual, GST on Financial Services, GST New Returns and GST e-invoicing
He is a founder of Singhania’s GST Consultancy & Co. , a firm which facilitates advisory, consultancy and litigation services on core indirect tax matters.
                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                 <li>
                    {" "}
He has been a panelist on Times Now, BTVI, FIEO, Business Standard, CII.                   </li>
                  <li> 
                    {" "}
He has received a letter of appreciation from the Minister of State of Finance, Shri Arjun Ram Meghwal, on the launch of his first book in 2016 which was published much before the launch of GST in India.                   </li>
                  <li>
                    {" "}
He has given his views on crucial developments in GST from time to time in Economic Times, Business Standard, The Hindu, etc.                  </li>
    <li>
                    {" "}
He has served as a Deputy General Manager, Taxation, in a reputed Software Company and has also served as a Senior Manager, Indirect Tax in one of the service sector company having turnover of around Rs 80,000 Crores, handling framework of tax policies, implementation of changes in GST, business advisory, compliance support, response to notices, summon & litigation including writ petition pertaining to GST.                  </li>
    <li>
                    {" "}
He has made several researches on GST and has laid down various conceptual papers. His forte is in analysing the business situation & accordingly advising the clients by articulating the provisions of the law.                    </li>
                  
                </ul>
                
              </div>
            </section>
      <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/7rC53PX/CA-Vikas-Pic.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
CA. Vikas Golcha                 </h4>

                <h5 className="ml-0 bold">
More than 8 years of Experience as a Professional, Author and Speaker. 
Currently, working as a Partner in firm S C MAHANDRU & CO. Chartered Accountants (C.G.).

                </h5>
    <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
    Faculty in Certification Course on GST (ICAI) in Raipur, Chhattisgarh.                  </li>
                  <li>
                    {" "}
    Empaneled National Faculty of GST - Institute of Chartered Accountants of India. 
                  </li>
                  <li>
                    {" "}
    Empaneled Expert faculty on GST - National academy of Indirect Tax and Customs (NACIN), ministry of Finance, Government of India
                  </li>
                  <li>
                    {" "}
                    Visiting Faculty Chhattisgarh State GST Department to train state GST officers on different topics of GST.
                  </li>
                  
                  <li>
                    {" "}
                    Visiting Faculty Institute of Chartered Accountants of India on topic Goods & service tax (GST) including CPE Hours Event at NIRC, RAIPUR, BHILAI, BILASPUR, RAIGARH and RAJNANDGAON (C.G.)
                  </li>
                  
                  <li>
                    {" "}
	Visiting Faculty of Institute of Company Secretaries of India, Raipur (C.G.) on GST.                  </li>
                </ul>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                 <li>
                    {" "}
He addressed 300+ Seminars / Webinars on GST, in which Trained more than 20000 Participants on Various topics of GST across India.                  </li>
                  <li> 
                    {" "}
He has handled Litigations on GST and service tax along with our legal associates at High court of Chhattisgarh, Central Excise and Service Tax appellate tribunal (CESTAT) , New Delhi ,Commissioner Appeal , Chhattisgarh with a Favorable Outcome.                 </li>
                  <li>
                    {" "}
Founder of Cloud based GST Academic Database www.gstsamadhan.com, subscribed by GST officials and GST Practitioners across India.                 </li>
    <li>
                    {" "}
He has authored 3 Books on GST – 1. GST E-way Bill , 2. GST-TDS, 3. GST -Basics.                 </li>
    
                  
                </ul>
                
              </div>
            </section>
	   <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/pZnkn2R/Yogesh-Pic.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">
CA. Yogesh Gaba
                </h4>

                <h5 className="ml-0 bold">
CA. Yogesh Gaba is a chartered accountant with area of expertise in the field of Indirect Taxation                </h5>

                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                 <li>
                    {" "}
In the initial stage of his career only, he successfully represented his clients before Customs Excise and Service Tax Appellate Tribunal (CESTAT).                   </li>
                  <li> 
                    {" "}
He has been instrumental in implementing GST in large number of companies across industries. Since then, he has been advising clients on various contentious issues under GST on regular basis.                   </li>
                  <li>
                    {" "}
He has also served exporters and importers in obtaining licenses / benefits under Foreign Trade Policy.                 </li>
    <li>
                    {" "}
Adding to the experience is his representation before the National Anti-profiteering Authority (NAA) in one of the large Consumer Durable Company.                </li>
    <li>
                    {" "}
He is a nominated faculty to the Office of the Director General of Audit-Central Receipt (DGCR) for in house training of officers.                  </li>
	  <li>
                    {" "}
He has actively been sharing knowledge before various trade forums, government offices etc.                </li>
                  
                </ul>
              </div>
            </section>
	   <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/Wf2SJPk/CMA-Harshad-S-Deshpande.jpg"
                />
              </div>

                <div className="ml-15 mt-15">
			<h4 className="ml-0 mt-0 bold">
			CMA HARSHAD S DESHPANDE (M.com, FCMA, CS, CISA(USA), CIMA (UK), ID, Insolvency Professional, Registered Valuer)</h4>

                <h5 className="ml-0 bold">
                  CMA Harshad S. Deshpande is practicing cost & management accountant and partner in M/S Harshad S Deshpande & Associates firm of practicing CMAs rendering professional services in the field of Cost & Management accounting, Finance, and Indirect Tax for more than 15 years of experience.
                </h5>
	        
                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    He has vast experience in Costing, Management accounting & Profitability Analysis vertical. 

                  </li>
                  <li>
                    {" "}
                    He has been instrumental in implementation of SAP CO for number of clients and providing retainer ship services. 

                  </li>
                  <li>
                    {" "}
                    He also had undertaken projects for Activity Based Costing & Activity Based Management.
                  </li>
                  <li>
                    {" "}
                    He has handled many assignments as IRP /RP / Liquidator for corporates having exposure from 
1 Cr to 2000 Cr. He has successfully resolved majority cases.

                  </li>
	  <li>
                    {" "}
                    He has completed more than 500+ valuation assignments as Registered Valuer for various purposes like for further issue of shares, Mergers & Amalgamations, Under Insolvency / liquidation, Strategic Decisions etc., for entities in various sectors.

                  </li>
	  <li>
                    {" "}
                    Written articles for various magazines like Management Accountant, WIRC Bulletin, MBA Research Journal, and online portals like Tax Guru, CA Club India and have received more than 1 lakhs hits.

                  </li>
	  <li>
                    {" "}
                    Member of Pune based social welfare organization ‘NINAD’ and participated in various social activities of organization like Helping people disrupted by natural disasters e.g. Gujrat Earthquake, Floods in Maharashtra etc.

                  </li>
                </ul>
                <h5 className="ml-0 bold">Professional Membership:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Chairman - WIRC of Institute of Cost Accountants of India.
                  </li>
                  <li>
                    {" "}
                    Treasurer - WIRC of Institute of Cost Accountant of India. 
                  </li>
                  <li>
                    {" "}
                    Secretary - Pune Chapter of Cost Accountants.
                  </li>
                  <li>
                    {" "}
                    Treasurer - Pune Chapter of Cost Accountants.
                  </li>
                  <li>
                    {" "}
                    He also has been nominated as Member in advisory committee of Insolvency Professional Agency of The Institute of Cost Accountants of India (IPA of ICAI).
                  </li>
                  
                  <li>
                    {" "}
                    Editor of ‘WIRC Bulletin” monthly knowledge sharing bulletin of the Institute for 3 years.
                  </li>
                 
                </ul>
	            </div>

	   </section>
	  	  <hr></hr>

            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/X8280bh/Kashish-PHD.jpg"
                />
              </div>

                <div className="ml-15 mt-15">

                <h4 className="ml-0 mt-0 bold">
			CA. KASHISH GUPTA (FCA, LL. B, B. Com (H))
</h4>

                <h5 className="ml-0 bold">
Kashish has 9+ years of experience in providing tax and legal services and is the Founding and Managing Partner at Paksh Legal with its head office in Delhi and Branch Office in District & Session Court, Chandigarh for providing services in the field of indirect taxation and civil disputes.                 </h5>
	        
                <h5 className="ml-0 bold">Significant Achievements:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
He successfully handled and represented varied matters on indirect taxes (including GST, VAT, Service tax, Customs, Excise).
                  </li>
                  <li>
                    {" "}
He has worked for multiple industry segments including Indian and foreign multinationals, PSUs, and Fortune 500 companies.
                  </li>
                  <li>
                    {" "}
He has privilege of becoming one of the India’s youngest taxation author in 2017 when his book - “Commentary on Revised Model GST Law” was published by Bharat Law House Private Limited.                  </li>
                  <li>
                    {" "}
Keen interest in Constitutional matters; and successfully argued several matters involving fine nuances of the Constitution of India before the Tribunals.

                  </li>
	  <li>
                    {" "}
He acts as a legal advisor to many renowned corporates in different industry segments.
                  </li>
                </ul>
                <h5 className="ml-0 bold">Deliberations::</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Delivered lectures/ talks on various subjects inter alia including GST, Service tax, VAT, central excise, Customs, etc. in various Chambers of Commerce & Industry, various forums of the Institute of Chartered Accountants of India, CAG etc.
                  </li>
                  <li>
                    {" "}
                    Faculty in the Institute of Chartered Accountants of India (ICAI).
                  </li>
                  <li>
                    {" "}
                    Guest faculty in trainings organized by PSUs & Corporates.
                  </li>
                  
                 
                </ul>
	  <h5 className="ml-0 bold">Authorship:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
Author of five books: “GST: Setting pace for joyful FY 2018-19” – Edition 3, GST: Annual Return and Audit – 1st Edition, GST Tracker – 1st Edition, etc.
                  </li>
                  <li>
                    {" "}
Contributed towards publications of the ICAI, Tax management India, Taxmann, Institute of Actuaries of India, pamphlets of various chambers of Commerce and Industry etc.                  </li>
                  <li>
                    {" "}
Contributed to several research-based articles in journals and websites                  </li>
                  
                 
                </ul>
	            </div>

	   </section>
	  <hr></hr>
            <section className="flex-desktop">
              <div className="mr-15 faculty-image-container">
                <img
                  className="rounded image-width"
                  src="https://i.ibb.co/ncT1Gw6/CA-Pitam-Goel.jpg"
                />
              </div>
              <div className="ml-15 mt-15">
                <h4 className="ml-0 mt-0 bold">CA. Pitam Goel </h4>
                

                <h4 className="ml-0 mt-0 bold">
                  CA. Pitam Goel is a Chartered Accountant having over a decade of professional experience in 
                  the fields of GST/Indirect Tax, Business Valuations Due Diligence and Business Advisory. 
                  He is a Co - Founder for M/s VPTP & Co and M/s Tattvam Advisors. 
                </h4>

                <h5 className="ml-0 bold">Area of Expertise:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    GST advisory, compliances, and audit {" "}
                  </li>
                  <li>
                    {" "}
                    Business Valuation – Compliance and Advisory
                  </li>
                  <li>
                    {" "}
                    Due diligence and Business Advisory
                  </li>
                </ul>

                <h5 className="ml-0 bold">Education & Professional Qualifications:</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    Chartered Accountant (ICAI)
                  </li>
                  <li>
                    {" "}
                    Registered Valuer under IBBI
                  </li>
                  <li>
                    {" "}
                    Diploma in DISA, Forensic and Ind AS {" "}
                  </li>
                  <li>
                    {" "}
                    B. Com (Hons)
                  </li>
                </ul>

                <h5 className="ml-0 bold">Expertise Summary::</h5>
                <ul className="faculty-list-style">
                  <li>
                    {" "}
                    He was earlier associated with leading Power generation company –Renew Power Private Limited & Lanco Infratech Ltd.
                  </li>
                  <li> He is a member of Institute of Chartered Accountant of India, having held all India merit positions at all stages.                   </li>

                  <li> He specializes in structuring business operations, GST Advisory and optimum structure, GST audit, GST Department audit and advisory support in the field of Indirect Tax.  </li>
                  <li> He has been deeply involved with various Industries ranging from real estate, BPO, manufacturing, EPC, Hospitality etc. for ensuring proper indirect related compliance and tax related structuring. </li>
                <li> He has many research papers and articles in reputed national and international journals to his credits.  </li>

                </ul>
              </div>
            </section>

<hr></hr>

<section className="flex-desktop">
<div className="mr-15 faculty-image-container">
  <img
    className="rounded image-width"
    src="https://i.ibb.co/j9tLdy5/Kartik-image.png"
  />
</div>
<div className="ml-15 mt-15">
  <h4 className="ml-0 mt-0 bold">
  CA Kartik Jindal (BBA, Ind AS (ICAI) 
  </h4>

  <h5 className="ml-0 bold">
  CA Kartik Jindal is Chartered Accountant who achieve All India Rank – 9 and was one of the Top scorers in Financial Reporting examination of CA Final level of ICAI.

  </h5>

  <h5 className="ml-0 bold">Associations and Deliberations:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Faculty of CA Final Financial Reporting    </li>
    <li>
      {" "}
      Delivered various sessions on Diploma course IFRS in Delhi university.    </li>
    <li>
      {" "}
      He Has contributed in Comprehensive book on Ind AS 'Treatise on Ind AS' authorized by CA. Alok K. Garg.    </li>
      <li>
      {" "}
      Mentor to CA Students to help them to crack Examination.    </li>
    
  </ul>
  <h5 className="ml-0 bold">Working Experience:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Having experience of Auditing of various listed company and unlisted companies in which Ind AS is applicable like Jindal saw Limited, Jindal Coke Limited, Jindal United steel Limited, Jindal intralogistics Limited, United Textile Limited and others.
    </li>
    <li>
      {" "}
      Preparation of Financials statements in Ind AS of various companies.    </li>
    
    
  </ul>
</div>
</section>
<hr></hr>

<section className="flex-desktop">
<div className="mr-15 faculty-image-container">
  <img
    className="rounded image-width"
    src="https://i.ibb.co/5FWT9NT/CA-Sonia-Arora.jpg"
  />
</div>
<div className="ml-15 mt-15">
  <h4 className="ml-0 mt-0 bold">
  CA. Sonia Arora (B. Com, CA, CMA) 
  </h4>

  <h5 className="ml-0 bold">
  CA. Sonia Arora is Chartered Accountant having more than a decade of cross functional experience in Accounts, Finance and Indirect taxation. She is currently working as Founder at 'Sonia K & Associates'.
  </h5>

  <h5 className="ml-0 bold">Professional Membership:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Chairperson - Jalandhar Branch of NIRC of ICAI.    </li>
    <li>
      {" "}
      Co-opted Member - Women Empowerment Committee of NIRC of ICAI, New Delhi   </li>
    <li>
      {" "}
      Vice Chairperson - Jalandhar Branch of NIRC of ICAI for 2020-21.    </li>
      <li>
      {" "}
      Faculty Member - Career Counseling Cell of ICAI   </li>
    
  </ul>
  <h5 className="ml-0 bold">Significant Acheivements:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      GST Faculty for GST certification course Conducted By Various esteemed forum like VOCA, New Delhi, Lovely Professional University Jalandhar, Hans Raj  Mahila  Maha Vidhyalaya, Jalandhar, GST Mahakumbh at  Panipat.     </li>
    <li>
      {" "}
      Deliberated in Various seminars, Webinars, Workshop and Open discussion on GST.    </li>
      <li>
      {" "}
      Member of ITRAA (Independent Tax Research and Analysis Association) a group of professionals over the country who were passionate and energetic towards their Valuation contribution on GST matters where only 25 GST Experts from all over the Country explore new dimensions of GST.   </li>
      <li>
      {" "}
      First Female Elected Member of Jalandhar Branch of NIRC of ICAI.    </li>
    
    
  </ul>
</div>
</section>
<hr></hr>

<section className="flex-desktop">
<div className="mr-15 faculty-image-container">
  <img
    className="rounded image-width"
    src="https://i.ibb.co/cDp75Rm/CA-Sumit-Garg.jpg"
  />
</div>
<div className="ml-15 mt-15">
  <h4 className="ml-0 mt-0 bold">
  CA Sumit Garg (CA, DISA)   </h4>

  <h5 className="ml-0 bold">
  Founder of CASA  </h5>

  <h5 className="ml-0 bold">Significant Achievements:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Certificate holder by ICAI on Valuation, Concurrent Audit and NPO & Cooperatives and Forensic Audit & Fraud Prevention, Peer Review and perusing LL.B   </li>
    <li>
      {" "}
      Founder Partner of M/s Sumit Mohit & Company, Chartered Accountants.  </li>
    <li>
      {" "}
      Socially active in various social organization like CAWO, UPMM and Agarwal Sabha and founder of CASA NGO.    </li>
      <li>
      {" "}
      Expert in Statutory Audit under companies Act, Standards on Auditing and Law relating to it and delivered more than 50 programs at various branches and Study circles.   </li>
    
  </ul>
  <h5 className="ml-0 bold">Professional Membership:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Chairman NICASA of NIRC of ICAI 2018-19    </li>
      <li>
      {" "}
      Treasurer NIRC of ICAI 2017-18    </li>
      <li>
      {" "}
      Secretary of NIRC of ICAI 2016-17  </li>
  </ul>
</div>
</section>
<hr></hr>

<section className="flex-desktop">
<div className="mr-15 faculty-image-container">
  <img
    className="rounded image-width"
    src="https://i.ibb.co/dM5vsNJ/CA-Suhel-Goel.jpg"
  />
</div>
<div className="ml-15 mt-15">
  <h4 className="ml-0 mt-0 bold">
  CA. Suhel Goel (FCA, CPA, B. Com (Hons.), D.I.S.A), Sand Martin Group of Companies   </h4>

  <h5 className="ml-0 bold">
  Mr. Suhel Goel being a Fellow Member of the Institute of Chartered Accountants of India (ICAI), has also achieved his Accountancy Degree with Distinction from the American Institute of Certified Public Accountants (AICPA). He also has a bachelor’s in commerce with distinction from the Delhi University and holds a Degree in Information System Audits (DISA) from the ICAI.  </h5>

  <h5 className="ml-0 bold">Significant Achievements:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      He is a second-generation leader to join the family practice viz. M/s Sunil Goel & Associates, CAs (established in the year 1980) along with its sister arm Sand Martin Consultants (a leading KPO), together providing expert consultancy, advisory and Finance & Accounts Outsourcing Services to both domestic and international clients. The firm has offices in New Delhi, Mumbai, Noida & Gurgaon with a team of over 350+ Qualified Professionals.    </li>
    <li>
      {" "}
      On the professional side, he oversees multiple facets of the firm including Personal & Corporate Tax Advisory services besides advising on issues relating to corporate advisory, cross border transactions with the special reference to Double Taxation Avoidance Agreements and advisory on Foreign Investment into India and FCRA matters.  </li>
    <li>
      {" "}
      He also supervises the Firms Assurance Practice and Tax Litigation services before the various appellate Authorities besides making representations for approvals from the Reserve Bank of India, Ministry of Home Affairs (FCRA) etc. Mr. Suhel Goel regularly writes papers on various topics of both national and international impact on Corporates/Individuals from an impact assessment of various regulatory and compliance changes mooted by the Government. Additionally, he has spoken at and addressed various forums in the past including hosting various national and international conferences in India for various accounting networks that the firm is a part of.    </li>
      <li>
      {" "}
      Being a fellow Rotarian, Mr. Suhel Goel has been closely involved both personally and professionally with advising entities operating in the Non-Profit sector for the last several years. He has been assisting NGOs with their needs both personally and professionally in the areas of entity structuring, seeking exemptions from taxation, audit & assurance needs, grant-funding (including FCRA matters) and general compliances etc. In addition, he has been advising such entities for their needs to setup proper internal structures with a view to achieve long-term financial and organization stability. He has spoken at and addressed NGOs at several forums in the past.    </li>
      <li>
      {" "}
      On the Outsourcing side, he has been working with the Sand Martin Group for over 15 years now, currently leading the Service Delivery Function assisting several CA practices and SMEs in the US, UK, Australia and allied English-speaking countries of the world for their outsourcing needs. He has been closely supervising the client facing and corporate roles, business development function for the company over the years of his working with the Sand Martin Group besides playing a leading role in the critical areas of data and network security.   </li>
  </ul>
  <h5 className="ml-0 bold">Professional Membership:</h5>
  <ul className="faculty-list-style">
    <li>
      {" "}
      Chairman NICASA of NIRC of ICAI 2018-19    </li>
      <li>
      {" "}
      Treasurer NIRC of ICAI 2017-18    </li>
      <li>
      {" "}
      Secretary of NIRC of ICAI 2016-17  </li>
  </ul>
</div>
</section>

 <hr></hr>

<section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/3Ss4RdX/Sangam-Picture.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA. Sangam Kumar Aggarwal (B.Com (H), FCA, LLB, DISA)      </h4>
    
      <h5 className="ml-0 bold">
        CA. Sangam Aggarwal is a seasoned tax practitioner having more than a decade years of rich and profound experience in the field of taxation (direct & indirect) and advisory.    
      </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          He had made representations for a widely diversified cross section of industries before Authority for Advance Rulings, CIT (A), ITAT, and other appropriate forums.    </li>
        
        
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
            {" "}
            Co-opted member of Corporate & Aliied Laws and Corporate Governance Committee of NIRC of ICAI (2019-20)    </li>
          <li>
            {" "}
            Certificate holder of Concurrent Audit from ICAI   </li>
            <li>
            {" "}
            Visiting faculty of ICAI and have deliberated on various forums of ICAI    </li>
        
        
      </ul>
      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
            {" "}
            Currently managing partner of a firm of Chartered Accountants    </li>
          <li>
            {" "}
            Previously worked with IFFCO Tokio General Insurance Co. Ltd. as Manager in Finance & Accounts   </li>  
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/DVphyNZ/Picture-CA-Rattan-Singh-Yadav.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA. Rattan Yadav (FCA, LLB, B.Com) 
      </h4>
    
      <h5 className="ml-0 bold">
        CA. Rattan Singh Yadav is an accomplished finance and audit professional and a member of The Institute of Chartered Accountant of India since 1997 with more than 2 decades of experience in the areas of finance and accounting, internal and external audits, internal controls, and risk management. Currently is a partner with S R J N & CO LLP.      </h5>
    
      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Statutory Audits including Banks & PSU    </li>
        <li>
          {" "}
          Specialist in the Tax Audit and Internal Audit.  </li>
        <li>
          {" "}
          Expertise in Corporate Law & Procedures   </li>
          <li>
          {" "}
          Expertise in devising Internal Control System   </li>
          <li>
            {" "}
          Implementation of Accounting System for Corporate and others   </li>
            <li>
                {" "}
          Handling of Direct and Indirect Tax cases and consultancy Services   </li>
        
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Founder Member of Dwarka CPE Study Circle of NIRC of ICAI, past Convener.    </li>
        <li>
          {" "}
          Delivered many presentations including Impact of Demonetization on CA Profession. Past Member of Indirect Tax Committee of NIRC of ICAI.    </li>
           
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/W5WMj8M/Hansraj.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA. Hans Raj Chugh (FCA, FCS, LLB, IP)   </h4>
    
      <h5 className="ml-0 bold">
        He has more than 29 Years of experience in the professional practice relating to Foreign Trade Policy, Goods & Services Tax, Customs, FEMA & Banking related laws. He is founder partner in M/s. A S H M & ASSOCIATES, Chartered Accountants, New Delhi.  </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          He is a regular speaker on Goods & Services Tax (GST), Foreign Trade Policy, Customs Law, Insolvency & Bankruptcy Code (IBC).  </li>
        <li>
          {" "}
          He is also a board member of Indian Institute of Insolvency Professionals of ICAI. </li>
        <li>
          {" "}
          He had served in various Indian Corporates and MNCs in his professional career.   </li>
          
        
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Central Council Member of ICAI, 2019-22     </li>
          <li>
          {" "}
          Chairman of the Committee for Members in Industry and Business (CMI&B), 2021-22   </li>
          <li>
          {" "}
          Member of NIRC of ICAI for two terms i.e. 2010-13 & 2013-16  </li>
          <li>
            {" "}
          Secretary during the period 2010-11 & Vice-Chairman during 2015-16  </li>
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/QDrHbtM/CA-Pankaj-Periwal-Picture.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        Mr. Pankaj Periwal    </h4>
    
      <h5 className="ml-0 bold">
        Mr. Pankaj Periwal is one of the elite professionals to qualify as an associate of ICAI in 1998 with over two decades of vast experience in core fields of Audit & Tax consultancy. Currently, he is the Managing Partner at Ludhiana branch of the firm and is responsible for overall coordination, planning, leadership, reporting and liaison with clients in connection with audit of Private & Public sector audits.   </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          President - Maheshwari Samaj, Ludhiana.   </li>
        <li>
          {" "}
          Member - Ludhiana District Taxation Bar Association and Indirect Taxes Consultants Association.  </li>
        <li>
          {" "}
          He had the opportunity to share dais with the Honourable President of India Sh. Ram Nath kovind on the occasion of CA day. He has also shared platforms with some of the leaders of the country, like Railways and Commerce Minister Sh. Piyush Goyal.    </li>
          
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Chairman- Northern India Regional Council of ICAI, 2018  </li>
          <li>
          {" "}
          Chairman- Ludhiana Branch of ICAI, 2011    </li>
        
      </ul>
      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          His experience of handling internal audits, bank audits has been of great value to the firm. He is been presently handling Direct & Indirect Tax Audits and its related matters. He brings great experience and perspective to our team as he has been the tax advisor and counsel for many large Industrial houses. He is also known for his credentials in assisting several clients in assurance services, both statutory and internal, as well as income tax survey and search cases.  </li>
        
        
      </ul>
    </div>
    </section>
<hr></hr>

<section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/ZTxWjNn/Pic-CA-Aishwary-Gupta.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA. Aishwary Gupta       </h4>
    
      <h5 className="ml-0 bold">
        CA. Aishwary Gupta is a Chartered Accountant and an Executive alum of The Indian Institute of Management, Calcutta. He is a blockchain consultant on a mission to spread awareness about Blockchain technology and its underlying potential through various courses and a daily newsletter.      </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          He had been a nominee of the Finance Visionary Award at Amex, the highest award for a finance professional at Amex.    </li>
          <li>
            {" "}
            He is a public speaker and a Blockchain Trainer with in-depth knowledge as well as practical exposure to Blockchain.    </li>
            <li>
                {" "}
            He is associated with BRICS CCI, is a Delegate at Vision India Foundation - a policy think tank that is focused on building nation builders, and is a Harvard Business Review Select Member.   </li>
          
        
        
      </ul>
     
      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
            {" "}
        In the past, he has worked at American Express and PwC.   </li>
          <li>
            {" "}
        He is working on empowering finance professionals to learn and understand technologies like RPA and Blockchain.  </li>  
            <li>
                {" "}
         
        He has worked on various blockchain initiatives specifically in cross-border payments, supply chain finance, and the insurance sector, and has a place at various advisory boards with a CXO level network which helps him leverage a tremendous amount of opportunities, venture, and angel capital, and expertise in various domains.   </li>  
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/YcHCJvL/Radha-Halbe.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        Adv. Radha Halbe 
      </h4>
    
      <h5 className="ml-0 bold">
        Adv Radha Halbe is a practicing advocate at DHL Legal since last two year. She had professional experience of more than 4 years in the field of direct and indirect tax litigation.    </h5>
    
      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          She regularly appears before the Bombay High Court, various tribunals, Metropolitan Magistrates and first appellate authority.   </li>
        <li>
          {" "}
          Assisted in the matters of Benami Law, IBC and other allied laws </li>
        
        
      </ul>
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Regularly contributes articles on the taxation and allied laws to various leading legal journals, magazines and websites such as taxmann, itatonline.com, The Chamber's Journal published by The Chamber Of Tax Consultants etc.   </li>
        <li>
          {" "}
          Participated in Mock Tribunals and delivered lectures at various forums and CA Study Circles.   </li>
           
      </ul>
      <h5 className="ml-0 bold">Academic Summary:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          B.Com. from Mumbai University (R. A. Podar College of Commerce and Economics, Matunga)  </li>
        <li>
          {" "}
            </li>
           
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/v33jswf/Vineet-Rathi-Picture.jpg"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA Vineet Rathi (FCA, DISA, LLB, M.Com)
</h4>
    
      <h5 className="ml-0 bold">
        CA Vineet Rathi is a practicing Chartered Accountant have more than a decade year of experience and Senior Partner of M/s Vineet Rathi & Associates. At present, he is CICASA Chairman of ICAI – Ghaziabad.   </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          He was former visiting faculty of CA Institute of Indirect Taxes for CA Final students  </li>
        <li>
          {" "}
          He also had been faculty of Orientation and General Management and Communication Classes at ICAI and ICSI Level. </li>
        <li>
          {" "}
          He has provided lectures on indirect taxes in various Government, private  organization, colleges, universities, ICAI, ICSI and ICMAI.   </li>
          
        
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          CICASA Chairman - Ghaziabad Branch of ICAI     </li>
          <li>
          {" "}
          Member - Managing Committee of ICAI Ghaziabad Branch, 2019-2022  </li>
          <li>
          {" "}
          Member- Managing Committee of ICAI Ghaziabad Branch, 2016-2019  </li>
          <li>
            {" "}
          Secretary - Ghaziabad Branch of ICAI, 2018-19  </li>
          <li>
            {" "}
          CICASA Chairman - Ghaziabad Branch of ICAI, 2017-18 </li>
          <li>
            {" "}
          Treasurer - Ghaziabad Branch of ICAI, 2016-17  </li>
      </ul>

      <h5 className="ml-0 bold">Working Experience:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          He has qualified DISA, certified course on concurrent audit of banks, certified course on forensic audit and fraud detector by ICAI. </li>
        <li>
          {" "}
          He is providing advisory, compliance and litigation services in the area of GST. </li>
        <li>
          {" "}
          He has specialization in handling indirect appeals upto Tribunal level.   </li>
          
        
      </ul>
    </div>
    </section>
    <hr></hr>
    
    <section className="flex-desktop">
    <div className="mr-15 faculty-image-container">
      <img
        className="rounded image-width"
        src="https://i.ibb.co/kJdrmz5/Amarjit-Chopra-2-770x433.webp"
      />
    </div>
    <div className="ml-15 mt-15">
      <h4 className="ml-0 mt-0 bold">
        CA. Amarjit Chopra   </h4>
    
      <h5 className="ml-0 bold">
        Highly acclaimed Chartered Accountant for more than 46 years, CA. Amarjit Chopra is a senior partner of GSA & Associates LLP, Chartered Accountants. He is presently on the Board of TATA Power Delhi Distribution Limited, RICO Auto Industries Limited, RICO Investment Ltd, Roop Automotives Ltd, and ICAI Accounting Research Foundation Ltd.   </h5>
    
      <h5 className="ml-0 bold">Significant Achievements:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Chairman - National Advisory Committee on Accounting Standards by the Central Government for four years till 30th September, 2018.  </li>
        <li>
          {" "}
          Chaired several important committees that dealt with Auditing and Accounting Standards, Corporate Governance and Banking at the national level.  </li>
        <li>
          {" "}
          He was responsible for introducing IFRS Certificate Course in India and Middle East.    </li>
          <li>
            {" "}
         Participated in Board meetings of IFAC, CAPA and SAFA at the international level.  </li>
         <li>
            {" "}
         Part of IFRS training programmes for members of Nepal and Myanmmar Institutes. </li>
         <li>
            {" "}
        Lead the teams for conducting IFRS training programmes in Bhutan.  </li>
         <li>
            {" "}
        Technical Advisor- International Audit and Assurance Standards Board of IFAC, 2009-10.  </li>
         <li>
            {" "}
        Served on the IFAC Committee of Professional Accountancy Organisation Development for a period of three years (2010-13).  </li>
         <li>
            {" "}
        Vice Chairman - IFAC Committee of Professional Accountancy Organisation Development, 2012-13.  </li>
         <li>
            {" "}
        He was appointed by the Government of India to investigate complex issues in relation to M/s Xerox India Ltd.  </li>
         <li>
            {" "}
        Investigated several other matters during his career as professional auditor.  </li>
         <li>
            {" "}
        Conferred honorary membership of the Institute of Chartered Accountants of Australia and New Zealand.  </li>
         <li>
            {" "}
        Honorary Life membership of the Association of International Accountants London. </li>
         <li>
            {" "}
        Roaster of IMF as a resource person and has conducted missions in Mauritius, Bhutan and Seychclles. </li>
         <li>
            {" "}
        Prolific speaker and has delivered more than 2950 lectures on various topics including Accounting and Auditing Standards, IFRS, Banking including Financial Inclusion, Insurance, Corporate Laws, Corporate Governance, Ethical Standards ,Peer Review both in India as well as abroad </li>
      </ul>
      <h5 className="ml-0 bold">Professional Membership:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          President - The Institute of Chartered Accountants of India (ICAI), 2010-11. </li>
          <li>
          {" "}
          Vice President - The Institute of Chartered Accountants of India (ICAI), 2009-10. </li>
          <li>
            {" "}
          Chairman - Accounting Standards Board and Auditing and Assurance Standards Board of ICAI. </li>
            <li>
                {" "}
          Vice-Chairman - High Powered Committee of ICAI that examined Satyam fraud in India. </li>
        
      </ul>
      <h5 className="ml-0 bold">Various Nominations:</h5>
      <ul className="faculty-list-style">
        <li>
          {" "}
          Govt of India as a member of National Financial Reporting Authority (NFRA) and also to a Group constituted by the Govt of India to make suggestions on Consultation Paper on Auditing Profession. </li>

          <li>
            {" "}
          The Central Govt. on Bench- II of Disciplinary Committee of ICAI for a period of four years from 2018 to 2022 </li>

      <li>
        {" "}
        Central Govt. as Chairman of Committee to revise Companies Auditor’s Report order 2016 </li>
  <li>
    {" "}
    The Central Govt. on the Standing Committee for Decriminalizing the Companies Act 2013 </li>
<li>
    {" "}
    Member of Investor Education and Protection Fund Authority by Central Govt. of India </li>
<li>
    {" "}
    IRDA on the standing committee of Accounting Issues in Insurance Companies </li>
        
    <li>
        {" "}
    Independent director to Bank of Baroda (2006-09) and Indian Bank (2011-14), member of the Securities & Exchange Board Committee on Disclosures and Accounting Standards, Board Member of the Insurance Regulatory Authority of India and member Audit Committee of the Reserve Bank of India. </li>

    <li>
        {" "}
    SEBI Committee to review the performance of corporates as regards governance. </li>    
    
      </ul>
    </div>
    </section>
    
   

          </div>
         </div>

      </Layout>
    )
  }
}

export default Faculty

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
